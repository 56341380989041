 const apiServer = "https://api.farefinder.co.uk";
 //const apiServer = "http://localhost:1337";

const tripOptions = {
  0: "One Way",
  1: "Round Trip",
  2: "Multi-City",
};

const ticketTypes = {
  Y: "Economy",
  C: "Business",
};

const tripTypes = [
  {
    id: 0,
    name: "One way",
  },
  {
    id: 1,
    name: "Round trip",
  },
  {
    id: 2,
    name: "Multicity",
  },
];

const ticketClasses = [
  {
    id: 0,
    name: "Economy",
    code: "Y",
  },
  {
    id: 1,
    name: "Business",
    code: "C",
  },
  {
    id: 2,
    name: "First Class",
    code: "F",
  },
];

const mainFilters = {
  cheapest: "Cheapest",
  fastest: "Fastest",
  best: "Best",
  earliest: "Earliest",
};

export {
  apiServer,
  tripOptions,
  ticketTypes,
  mainFilters,
  tripTypes,
  ticketClasses,
};
