import { atom } from "recoil";

export const selectedMainTabAtom = atom({
  key: "selectedMainTabAtom",
  default: "flights",
});

export const selectedCurrencyAtom = atom({
  key: "selectedCurrencyAtom",
  default: "GBP",
});

export const sidebarStateAtom = atom({
  key: "sidebarStateAtom",
  default: false,
});

export const dealsLocation = atom({
  key: "dealsLocation",
  default: "",
});

export const destinationsAtom = atom({
  key: "destinationsAtom",
  default: [],
});
