import { atom } from "recoil";
import RegionData from "../data/RegionData";

export const ipv4 = atom({
  key: "ipv4",
  default: "",
});

export const countryState = atom({
  key: "countryState",
  default: RegionData[1],
});

export const localAirportState = atom({
  key: "localAirportState",
  default: "LON",
});
