import axios from "axios";
import Server from "./Server";

export const getSearchId = async (searchObject) => {
  try {
    const res = await Server.post("/flight/getsearchid", searchObject);
    if (res.status === 200) {
      return res;
    }
  } catch (e) {
    return e.response.data;
  }
};

export const getSearchResults = async (searchId) => {
  console.log("Before waiting for 5 seconds");
  await new Promise(resolve => setTimeout(resolve, 5000));
  console.log("After waiting for 5 seconds");
  try {
    if (searchId) {
      const res = await Server.get(`/flight/search_results/${searchId}`);
      if (res.status === 200) {
        return res.data;
      }
    }
  } catch (e) {
    return e.response.data;
  }
};

export const getAirportNames = async (airportCode) => {
  //check if airportCode contains brackets
  if (!airportCode.includes("(")) {
    return axios.get(
      `https://autocomplete.travelpayouts.com/places2?term=${airportCode}&locale=en&types[]=city&types[]=airport`
    );
  }
};

export const getAgencyData = async (searchId, termUrl) => {
  const reply = await Server.get(
    `/flight/agencyData?searchId=${searchId}&termUrl=${termUrl}`
  );

  return reply.data;
};

export const getCheapest = async (searchObj) => {
  const reply = await Server.post("/flight/cheapest", searchObj);
  return reply.data;
};

export const getDestinations = async (searchObj) => {
  const reply = await Server.post("/flight/destinations", searchObj);
  return reply.data;
};

export const getAllAirports = async () => {
  return Server.get(`/airports/all`);
};

export const getAllCities = async () => {
  return Server.get(`/cities/all`);
};
