import React, { useEffect } from "react";
import { HashRouter as Router, Link, Route, Routes } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useQuery } from "react-query";
import CookieConsent from "react-cookie-consent";

import { ipv4, localAirportState } from "./atoms/geoAtom";
import { dealsLocation } from "./atoms/generalAtoms";
import * as GeoLocation from "./api/GeoLocation";
import * as TravelPayoutAPI from "./api/TravelPayout";

function App() {
  const LazyWorkWithUs = React.lazy(() => import("./pages/WorkWithUs"));
  const LazySupport = React.lazy(() => import("./pages/Support"));
  const LazyBooking = React.lazy(() => import("./pages/Booking"));
  const LazyPrice = React.lazy(() => import("./pages/Price"));
  const LazySearching = React.lazy(() => import("./pages/Searching"));
  const LazyOurStory = React.lazy(() => import("./pages/OurStory"));
  const LazyAboutUs = React.lazy(() => import("./pages/AboutUs"));
  const LazyCookiePolicy = React.lazy(() => import("./pages/CookiePolicy"));
  const LazyPrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
  const LazyTerms = React.lazy(() => import("./pages/Terms"));
  const LazyTicketResults = React.lazy(() => import("./pages/TicketResults"));
  const LazyAgentDeals = React.lazy(() => import("./pages/AgentDeals"));
  const LazyDestinations = React.lazy(() => import("./pages/Destinations"));
  const LazyComingSoon = React.lazy(() => import("./pages/ComingSoon"));
  const LazyHome = React.lazy(() => import("./pages/Home"));

  const setUserIp4 = useSetRecoilState(ipv4);
  const setDealsLocation = useSetRecoilState(dealsLocation);
  const setLocalAirport = useSetRecoilState(localAirportState);

  const { data: geoData } = useQuery("geoData", GeoLocation.getGeoInfo);
  const { data: locData } = useQuery(
    "locData",
    GeoLocation.getAllLocationsForHomepage
  );

  const getLocalAirport = async (cityName, countryName, countryCode) => {
    await TravelPayoutAPI.getAirportNames(cityName).then((response) => {
      let tempData = response.data;
      let tempRes = [];

      if (tempData.length > 0) {
        tempRes = tempData.filter(
          (result) => result.country_code === countryCode
        );

        if (tempRes.length > 0) {
          setLocalAirport(tempRes[0]);
        }
      }
      if (tempRes.length < 1) {
        TravelPayoutAPI.getAirportNames(countryName).then((result) => {
          let temp = result.data;
          let tempReply = [];

          if (temp.length > 0) {
            tempReply = temp.filter((res) => res.country_code === countryCode);

            setLocalAirport(tempReply[0]);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (geoData && geoData.ip) {
      setUserIp4(geoData.ip);
      getLocalAirport(geoData.city, geoData.country_name, geoData.country_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoData]);

  useEffect(() => {
    if (locData && locData.length > 0) {
      setDealsLocation(locData[0].locationName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locData]);

  return (
    <div className="App">
      <React.Suspense fallback={<p>Loading page...</p>}>
        <Router>
          <Routes>
            <Route exact path="/" element={<LazyHome />} />
            <Route path="/trip-details" element={<LazyTicketResults />} />
            <Route
              path="/flight-deals/:proposalSign"
              element={<LazyAgentDeals />}
            />
            <Route path="/work-with-us" element={<LazyWorkWithUs />} />
            <Route path="/support" element={<LazySupport />} />
            <Route path="/booking" element={<LazyBooking />} />
            <Route path="/price" element={<LazyPrice />} />
            <Route path="/destinations" element={<LazyDestinations />} />
            <Route path="/about-us" element={<LazyAboutUs />} />
            <Route path="/our-story" element={<LazyOurStory />} />
            <Route path="/searching" element={<LazySearching />} />
            <Route path="/terms" element={<LazyTerms />} />
            <Route path="/privacypolicy" element={<LazyPrivacyPolicy />} />
            <Route path="/cookiepolicy" element={<LazyCookiePolicy />} />
            <Route path="*" element={<LazyComingSoon />} />
          </Routes>
          <CookieConsent
            location="bottom"
            onDecline={() => {
              //close the tab
              window.close();
            }}
            buttonText="I accept"
            declineButtonText="I decline"
            cookieName="farefinder"
            style={{ background: "#2B373B" }}
            buttonStyle={{
              color: "white",
              fontSize: "13px",
              background: "#0093ff",
            }}
            declineButtonStyle={{ color: "white", fontSize: "13px" }}
            expires={150}
          >
            This site uses cookies to offer you a better browsing experience.{" "}
            <span style={{ fontSize: "10px", color: "white" }}>
              View our{" "}
              <Link
                style={{ color: "white", textDecoration: "underline" }}
                to="/cookiepolicy"
              >
                cookie policy!
              </Link>
            </span>
          </CookieConsent>
        </Router>
      </React.Suspense>
    </div>
  );
}

export default App;
