import axios from "axios";
import Server from "./Server";

export const getGeoInfo = () =>
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

export const getAllLocationsForHomepage = async () => {
  try {
    const res = await Server.get("/home-location/all");
    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
