import ReactCountryFlag from "react-country-flag";

const RegionData = [
  {
    id: "1",
    name: "United States",
    currency: "USD",
    currencyCode: "usd",
    currencySymbol: "$",
    countryFlagComponent: <ReactCountryFlag countryCode="US" svg />,
  },
  {
    id: "2",
    name: "United Kingdom",
    currency: "GBP",
    currencyCode: "gbp",
    currencySymbol: "£",
    countryFlagComponent: <ReactCountryFlag countryCode="GB" svg />,
  },
  {
    id: "3",
    name: "Sri Lanka",
    currency: "LKR",
    currencyCode: "lkr",
    currencySymbol: "Rs",
    countryFlagComponent: <ReactCountryFlag countryCode="LK" svg />,
  },
];

export default RegionData;
